import { useEffect } from 'react';
import { Footer } from '../../components/Footer';
import { SupportButton } from '../../components/SupportButton';

const ErrorPage = ({ title }) => {
  useEffect(() => {
    title && (document.title = title);
  });
  return (
    <div className='App'>
      <div className='tag-container confirmation'>
        <div className={'left-col tag-bg-white tag-pt-xlarge tag-pb-xlarge'}>
          <img
            src='../images/logo_with_ogr.png'
            // style={{ height: '60px' }}
            alt=''
            className={'logo'}
          />
          <h1 className='tag-h1 lubalin tag-pt-large'>Hey 👋</h1>
          <p className='tag-body_regular tag-c-grey'>
            It seems you tried to open a link but something didn't work. Try to
            open the link again and if the problem persists contact your manager
            or contact our customer&nbsp;
            <a
              href='https://helpme.talentgarden.com/'
              target='_blank'
              rel='noreferrer'
              className='tag-body_bold tag-c-black'
            >
              support
            </a>
            .
          </p>
        </div>
        <div className={'right-col tag-bg-gray-50 tag-right_text'}>
          <SupportButton />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ErrorPage;
