import '../../App.scss';
import './Confirmation.scss';
import { useEffect } from 'react';

const Confirmation = ({ title }) => {
  useEffect(() => {
    title && (document.title = title);
  });

  return (
    <div className='App' onClick={() => { }}>
      <div className='tag-container confirmation'>
        <div className={'left-col tag-bg-white tag-pt-xlarge tag-pb-xlarge'}>
          <img
            src='../images/logo_with_ogr.png'
            // style={{ height: '60px' }}
            alt=''
            className={'logo'}
          />
          <h2 className='tag-h2 lubalin tag-pt-large'>
            Welcome 👋
          </h2>
          <p className='tag-body_regular tag-c-grey'>
            Your account was successfully created, you should’ve received a
            confirmation email with a link to access all services.
          </p>

          {/* <div className='app-container'>
            <h4 className='tag-h4 tag-c-black '>Download the app</h4>
            <p className='tag-body_regular tag-c-grey'>
              Open Talent Garden’s campuses door to enjoy the most innovative
              coworking network in europe.
            </p>
            <a
              href='https://talentgarden.app/'
              className={`tag-button tag-button__primary tag-button__primary--large tag-button__primary--orange tag-margin-auto tag-mt-tiny`}
            >
              DISCOVER THE NEW APP
            </a>
          </div> */}
        </div>
        <div className={'right-col tag-bg-gray-50 tag-right_text'} />
      </div>
    </div>
  );
};

export default Confirmation;
