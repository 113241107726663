import './../App.scss';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import InputForm from './../components/inputFields/InputForm';
import { PasswordInputForm, PhoneInput } from './../components/inputFields';
import countryCodes from './../utils/CountryCodes.json';
import { postFormData } from '../services';
import { Footer } from '../components/Footer';
import { SupportButton } from '../components/SupportButton';
import { ErrorToast } from '../components/ErrorToast';
import Confirmation from './Confirmation/Confirmation';

const Homepage = () => {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const company_name = params.get('company_name') || '';
  const hubspot_company_id = params.get('hubspot_company_id') || '';
  const hs_object_id = params.get('hs_object_id') || '';
  const hs_object_type = params.get('hs_object_type') || '';
  const initial_email = params.get('email') || '';
  const manager_name = params.get('manager_name') || '';
  const source = params.get('source') || '';
  const phone_prefix = params.get('phone_prefix') || '';
  const phone_number = params.get('phone_number') || '';

  if (!hs_object_id && hubspot_company_id) {
    params?.set('hs_object_id', hubspot_company_id);
  }

  if (!hs_object_type) {
    params?.set('hs_object_type', 'company');
  }

  const [forceCloseDropdown, setForceCloseDropdown] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorDescription, setErrorDescription] = useState('');

  const formRef = useRef();
  const phoneRegExp =
    /^\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{1,6}\)?)?$/;

  let validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is required!'),
    last_name: Yup.string().required('Last name is required!'),
    email: Yup.string()
      .required('Email is required!')
      .email('Email is not valid'),
    phone_number: Yup.string()
      .required('Phone number is required')
      .matches(phoneRegExp, 'Phone number is not valid'),
    phone_prefix: Yup.string()
      .required('Prefix is required!')
      .test(
        'empty-or-2-prefix-check',
        'Prefix is not valid',
        (prefix) => !prefix || prefix?.length >= 2
      )
      .test('is-in-country-list', 'Prefix is not valid', (prefix) =>
        countryCodes.some((elem) => elem?.dial_code === prefix)
      )
      .test('check +', 'Add + to the prefix', (prefix) => prefix?.[0] === '+')
      .test('too-long', 'Prefix is too long', (prefix) => prefix?.length <= 5),
    password:
      source !== 'hubspot'
        ? Yup.string()
          .required('Password is required')
          .matches(/[0-9]/, 'Password must contains at least one number')
          .matches(/[A-Z]/, 'Password must contains upper case letters')
          .matches(/[a-z]/, 'Password must contains lower case letters')
          .matches(
            /[$-/:-?{-~!"^_`[\]@]/,
            'Password must contains special characters'
          )
          .test(
            'too-long',
            'Password must be at least 8 characters long',
            (password) => password?.length >= 8
          )
        : null,
    passwordConfirmation:
      source !== 'hubspot'
        ? Yup.string()
          .required('Password is required')
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
        : null,
  });

  const onSelectCustomerHandler = (
    value = '',
    field = '',
    setFieldValue = () => true
  ) => {
    setFieldValue(field, value);
  };

  useEffect(() => {
    window?.scrollTo(0, 0);
  }, [accountCreated]);

  return (
    <div
      className="App"
      onClick={() => {
        setForceCloseDropdown(true);
        setTimeout(() => {
          setForceCloseDropdown(false);
        }, 10);
      }}
    >
      {!accountCreated ? (
        <div className="tag-container">
          <div className={'left-col tag-bg-white tag-pt-large tag-pb-xlarge'}>
            <img
              src="images/logo_with_ogr.png"
              // style={{ height: '40px' }}
              alt=""
              className={'logo'}
            />

            <h2 className="tag-h2 lubalin ">Create your profile</h2>
            <p className="tag-body_regular tag-c-grey">
              {
                'Enter your data to complete the signup and create your account. You will receive a confirmation email with a verification link.'
              }
            </p>
            <div className="company-info tag-mt-regular">
              {company_name && (
                <div className="company-info__child first">
                  <p className="tag-label_regular tag-c-grey">Your Team</p>
                  <h4 className={'tag-h4 tag-mt-tiny  tag-mb-tiny'}>
                    {company_name}
                  </h4>
                </div>
              )}
              {manager_name && (
                <div className="company-info__child">
                  <p className="tag-label_regular tag-c-grey">Team manager</p>
                  <h4 className={'tag-h4 tag-mt-tiny tag-mb-tiny'}>
                    {manager_name}
                  </h4>
                </div>
              )}
            </div>
            <Formik
              initialValues={{
                first_name: '',
                last_name: '',
                email: initial_email,
                phone_number: phone_number,
                phone_prefix: phone_prefix || '+39',
                password: '',
                passwordConfirmation: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                setIsSubmitting(true);
                setErrorMessage('');
                setErrorDescription('');
                const payload =
                  source !== 'hubspot'
                    ? {
                      first_name: values?.first_name,
                      last_name: values?.last_name,
                      email: values?.email,
                      phone_number: values?.phone_number,
                      phone_prefix: values?.phone_prefix,
                      password: values?.password,
                    }
                    : {
                      first_name: values?.first_name,
                      last_name: values?.last_name,
                      email: values?.email,
                      phone_number: values?.phone_number,
                      phone_prefix: values?.phone_prefix,
                    };

                postFormData(
                  queryString,
                  payload,
                  () => {
                    // success callback
                    setIsSubmitting(false);
                    setAccountCreated(true);
                  },
                  (data) => {
                    if (data && data?.code === '00U01') {
                      setErrorMessage('User already exists.');
                    }
                    if (data && data?.code === '00O01') {
                      setErrorMessage("Organization doesn't exists.");
                      setErrorDescription('Share your problem on slack!');
                    }
                    // error callback
                    setShowErrorToast(true);
                  }
                );
              }}
              innerRef={formRef}
            >
              {({
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
                setFieldValue,
              }) => {
                const checkActive = () => {
                  const errorsKeys =
                    source !== 'hubspot'
                      ? Object.keys(errors)
                      : Object.keys(errors)?.filter(
                        (key) =>
                          !['password', 'passwordConfirmation'].includes(key)
                      );

                  const valuesObj =
                    source !== 'hubspot'
                      ? Object.values(values)
                      : Object.keys(values)
                        ?.filter(
                          (key) =>
                            !['password', 'passwordConfirmation'].includes(
                              key
                            )
                        )
                        ?.map((key) => values[key]);

                  return (
                    !errorsKeys?.length && valuesObj?.every((elem) => elem)
                  );
                };

                return (
                  <>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(values);
                      }}
                      className="form tag-mt-large"
                    >
                      <InputForm
                        value={values?.first_name}
                        onChangeText={(value) => {
                          isSubmitting && setIsSubmitting(false);
                          showErrorToast && setShowErrorToast(false);
                          onSelectCustomerHandler(
                            value,
                            'first_name',
                            setFieldValue
                          );
                        }}
                        handleBlur={handleBlur('first_name')}
                        type={'text'}
                        formName={'first_name'}
                        labelText={'First name'}
                        isRequired={true}
                        placeholder={'First name'}
                        id={'first_name'}
                        customClasses={'tag-pb-regular'}
                        disabled={false}
                        error={errors.first_name}
                        touched={touched.first_name}
                      />
                      <InputForm
                        value={values?.last_name}
                        onChangeText={(value) => {
                          isSubmitting && setIsSubmitting(false);
                          showErrorToast && setShowErrorToast(false);
                          onSelectCustomerHandler(
                            value,
                            'last_name',
                            setFieldValue
                          );
                        }}
                        handleBlur={handleBlur('last_name')}
                        type={'last_name'}
                        formName={'last_name'}
                        labelText={'Last name'}
                        isRequired={true}
                        placeholder={'Last name'}
                        id={'last_name'}
                        customClasses={'tag-pb-regular'}
                        disabled={false}
                        error={errors.last_name}
                        touched={touched.last_name}
                      />
                      <InputForm
                        value={values?.email}
                        onChangeText={(value) => {
                          isSubmitting && setIsSubmitting(false);
                          showErrorToast && setShowErrorToast(false);
                          const valueWithoutSpace = value
                            ?.replace(/\s/g, '')
                            ?.trim();
                          onSelectCustomerHandler(
                            valueWithoutSpace,
                            'email',
                            setFieldValue
                          );
                        }}
                        handleBlur={handleBlur('email')}
                        type={'email'}
                        formName={'email'}
                        labelText={'Email'}
                        isRequired={true}
                        placeholder={'Email'}
                        id={'email'}
                        customClasses={'tag-pb-regular'}
                        disabled={initial_email}
                        error={errors.email}
                        touched={touched.email}
                      />
                      <PhoneInput
                        value={values?.phone_number}
                        prefix={values?.phone_prefix}
                        onChangeText={(value) => {
                          isSubmitting && setIsSubmitting(false);
                          showErrorToast && setShowErrorToast(false);
                          onSelectCustomerHandler(
                            value,
                            'phone_number',
                            setFieldValue
                          );
                        }}
                        onChangePrefix={(value) => {
                          isSubmitting && setIsSubmitting(false);
                          showErrorToast && setShowErrorToast(false);
                          onSelectCustomerHandler(
                            value,
                            'phone_prefix',
                            setFieldValue
                          );
                        }}
                        handleBlur={handleBlur('phone_number')}
                        type={'tel'}
                        formName={'phone_number'}
                        labelText={'Phone number'}
                        isRequired={true}
                        placeholder={'Phone number'}
                        id={'phone_number'}
                        customClasses={'tag-pb-regular'}
                        disabled={!!phone_number}
                        disabledPrefix={!!phone_prefix}
                        errorPhoneNumber={errors.phone_number}
                        errorPrefixNumber={errors.phone_prefix}
                        touched={touched.phone_number}
                        forceCloseDropdown={forceCloseDropdown}
                        closeAllDropdowns={() => {
                          setForceCloseDropdown(true);
                          setTimeout(() => {
                            setForceCloseDropdown(false);
                          }, 10);
                        }}
                        autoComplete={false}
                      />

                      {source !== 'hubspot' && (
                        <>
                          <PasswordInputForm
                            value={values?.password}
                            onChangeText={(value) => {
                              isSubmitting && setIsSubmitting(false);
                              showErrorToast && setShowErrorToast(false);
                              onSelectCustomerHandler(
                                value,
                                'password',
                                setFieldValue
                              );
                            }}
                            handleBlur={handleBlur('password')}
                            type={'password'}
                            formName={'password'}
                            labelText={'Password'}
                            isRequired={source !== 'hubspot' ? true : false}
                            placeholder={'Your password'}
                            id={'password'}
                            customClasses={'tag-pb-regular'}
                            disabled={false}
                            error={errors.password}
                            touched={touched.password}
                            showStrongPassword
                          />
                          <PasswordInputForm
                            value={values?.passwordConfirmation}
                            onChangeText={(value) => {
                              isSubmitting && setIsSubmitting(false);
                              showErrorToast && setShowErrorToast(false);
                              onSelectCustomerHandler(
                                value,
                                'passwordConfirmation',
                                setFieldValue
                              );
                            }}
                            handleBlur={handleBlur('passwordConfirmation')}
                            type={'password'}
                            formName={'passwordConfirmation'}
                            labelText={'Repeat password'}
                            isRequired={source !== 'hubspot' ? true : false}
                            placeholder={'Your password'}
                            id={'passwordConfirmation'}
                            customClasses={'tag-pb-regular'}
                            disabled={false}
                            error={errors.passwordConfirmation}
                            touched={touched.passwordConfirmation}
                            autoComplete={false}
                          />
                        </>
                      )}
                      <div className="button-footer ">
                        <div className="separator tag-mb-large" />
                        <button
                          type="submit"
                          className={`tag-button tag-button__primary tag-button__primary--large tag-button__primary--orange tag-mt-tiny ${!checkActive() || isSubmitting
                            ? 'tag-button__primary--disabled'
                            : null
                            }`}
                          disabled={!checkActive()}
                        >
                          CREATE ACCOUNT
                        </button>
                      </div>
                    </form>
                    {showErrorToast && (
                      <ErrorToast
                        closeToast={() => setShowErrorToast(false)}
                        showToast={showErrorToast}
                        customClasses={'tag-mt-regular '}
                        customMessage={errorMessage}
                        noTimeExpired
                        errorDescription={errorDescription}
                      />
                    )}
                  </>
                );
              }}
            </Formik>

          </div>
          <div className={'right-col tag-bg-gray-50 tag-right_text '} >
            <SupportButton />
          </div>
        </div>
      ) : (
        <Confirmation />
      )}
      <Footer />
    </div>
  );
};

export default Homepage;
